.youtubePlayer {
    position: absolute;
    top: 0;
}

h4 {
    font-size: 20px;
    margin: 0 0 10px;
    font-weight: normal;
}

.videoDemoWrapper {
    width: 100%;
    margin-top: 40px;
    z-index: 100;
    margin-bottom: 40px;
}

.aspectRatioWrapper {
    padding-top: 56.25%;
    height: 0;
    position: relative;
}

.playerSize {
    width: 60%;
    display: inline-block;
}


@media only screen and (max-width: 850px) {
    .playerSize {
        width: 100%;
    }
}

@media only screen and (max-width: 1300px) and (min-width: 850px) {
    .playerSize {
        width: 70%;
    }
}

@media only screen and (max-width: 1500px) and (min-width: 1300px) {
    .playerSize {
        width: 70%;
    }
}

@media only screen and (min-width: 1500px) {
    .playerSize {
        width: 60%;
    }
}


@media only screen and (max-width: 500px) {
 .videoDemoWrapper {
     margin-top: 30px;
 }
}