


.portalNavbar {
    background-color: #EDEDED;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    border-right: 2px var(--themeColourLight) solid;
    position: relative;
}

.selectextLogoNav {
    width: 80%;
    margin-top: 10px;
}

.navButtonsWrapper {
    margin-top: 100px;
    width: 100%;
}

.navButtonsWrapper div {
    font-weight: 800 !important;
}


.portalNavbar a {
    all: unset;
    cursor: pointer;
    width: 100%;
}

.navbarButton {
    width: 100%;
    font-size: 25px;
    line-height: 50px;
    border-top: 2px #EDEDED solid;
    border-bottom: 2px #EDEDED solid;
    font-weight: bold;
}

.activeNavItem {
    width: calc(100% + 4px);
    margin-left: -2px;
    border-top: 2px var(--themeColourLight) solid;
    border-bottom: 2px var(--themeColourLight) solid;
    background-color: #fafafa;
}

.navLogoutButton {
    width: 60%;
    line-height: 40px;
    margin: 0;
    display: inline-block;
}

.navLogoutButtonWrapper {
    position: absolute;
    bottom: 0px;
    text-align: center;
    width: 100%
}
