.selectextLogoHeader {
    font-size: 165px;
}

@media only screen and (max-width: 850px) and (min-width: 460px)  {
    .selectextLogoHeader {
        font-size: 80px;

    }
    .outlines {
        -webkit-text-stroke: 2px black !important;
    }
    .headerWrapper .getItWrapper .getItButton {
        min-width: 230px !important;
        font-size: 16px !important;
        height: 50px;
    }
    .smallChromeIcon {
        width: 25px !important;
        margin-left: 15px;
    }
    .descHeader {
        font-size: 20px !important;
        /* margin-bottom: 10px; */
    }
}

@media only screen and (max-width: 460px) {
    .selectextLogoHeader {
        font-size: 60px;

    }
    .outlines {
        -webkit-text-stroke: 2px black !important;
    }
    .headerWrapper .getItWrapper .getItButton {
        min-width: 230px !important;
        font-size: 16px !important;
        height: 50px;
    }
    .smallChromeIcon {
        width: 25px !important;
        margin-left: 15px;
    }
    .descHeader {
        font-size: 16px !important;
    }

    .outlines {
        -webkit-text-stroke: 2px black;
    }
}

@media only screen and (max-width: 1300px) and (min-width: 850px) {
    .selectextLogoHeader {
        font-size: 140px;
    }
    .descHeader {
        font-size: 25px !important;
    }
}


.descHeader {
    margin-bottom: 15px;
    margin-top: 0px;
    font-weight: 800;
}

.getItWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    flex-wrap: nowrap;
    margin-top: 30px;
}

.getItButton:first-child {
    padding-right: 10px;
}

.getItButton:last-child {
    padding-left: 10px;
}


.getItButton {
    max-width: 300px;
    width: 50%;
    box-sizing: border-box;
}

.getItImage {
    width: 100% ;
}

.headerWrapper {
    /* margin-top: 20px; */
}

.getItWrapper .getItButton {
    min-width: 350px !important;

    background-color: white !important;
    border: black 2px solid;
    margin-top: 0 !important;
    height: 70px;
    font-size: 25px;
    border-radius: 10px !important;
}

.getItButton:hover {
    background-color: #ededed !important; 
    /* color: var(--themeColourLight) !important; */
}

.getItButton:hover .smallChromeIcon {
    animation:spin 1s linear infinite;
}
@-moz-keyframes spin { 
    100% { -moz-transform: rotate(360deg); } 
}
@-webkit-keyframes spin { 
    100% { -webkit-transform: rotate(360deg); } 
}
@keyframes spin { 
    100% { 
        -webkit-transform: rotate(360deg); 
        transform:rotate(360deg); 
    } 
}

.smallChromeIcon {
    width: 40px;
    margin-left: 15px;
}

.selectextLogoHeader {
    font-weight: 800;
    margin: 0;
    text-rendering: optimizeLegibility;
    /* line-height: 100px; */

}

.outlines {
    color: white;
    -webkit-text-stroke: 3px black;
}

.descHeader {
    font-size: 30px;
}