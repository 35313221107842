.topBannerWrapper {
    height: 70px;
    background-color: var(--themeColourLight);
    display: flex;
    align-items: center;
    justify-content: center;
}

.makesureheader {
    font-size: 20px;
    text-align: left;
}

.topBannerWrapper .getItWrapper .getItButton {
    max-width: 500px !important;
    min-width: 300px !important;
    height: 50px !important;
    font-size: 20px !important;
}

.topBannerWrapper .smallChromeIcon {
    width: 25px;
}

@media only screen and (max-width: 850px) {
    .makesureheader {
        font-size: 14px !important;
    }

    .topBannerWrapper .smallChromeIcon {
        width: 20px !important;
    }

    .topBannerWrapper .getItWrapper .getItButton {
        min-width: 180px !important;
        font-size: 12px !important;
        height: 40px !important;
    }
}

@media only screen and (max-width: 1300px) and (min-width: 850px) {
    .makesureheader {
        font-size: 16px !important;
    }
    .topBannerWrapper .getItWrapper .getItButton {
        min-width: 230px !important;
        font-size: 16px !important;
        height: 50px;
    }
}


@media only screen and (max-width: 720px){
    .topBannerWrapper {
        display: none;
    }
}