.accountPageWrapper {
    height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}

.yourCreditsHeader, .accountSettingsHeader{
    margin-top: 0px;
}

.deleteAccountButton {
    width: 150px !important;
    margin-top: 20px !important;
    margin-bottom: 20px !important;
}

.userIconPicture {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-bottom: 20px;
    margin-top: 0px;
}

.accountSettingsHeader {
    margin-bottom: 10px;
    font-size: 30px;
}

.yesNoButtonsWrapper {
    display: flex;
    justify-content: space-evenly;
}

.greyedButton {
    background-color: #ededed !important;
}

.greyedButton:hover {
    background-color: #d5d5d5 !important;
}

.manageSubscriptionButton {
    margin-top: 5px !important;
    width: 200px !important;
    margin-bottom: 10px !important;
}

.youHaveCancelledText {
    font-size: 16px;
    color: var(--themeColourLight);
    margin-bottom: 0px;
}

.yourCreditsHeader {
    font-size: 30px;
}

.yourPlanHeader {
    font-size: 30px;
}

.accountPageLayout {
    display: flex;
    justify-content: space-evenly;
    margin: auto 0;
}

.yourMonthlyCreditsLayout, .accountSettingsLayout {
    width: 40%;
    /* margin: auto 0; */
  /* overflow-y: auto; */
}

.yourMonthlyCreditsLayout {
    padding-right: 10px;
    min-width: 315px;
}

.accountSettingsLayout {
    padding-left: 10px;
    min-width: 260px;

}

.youHaveCancelledYourPlanText {
    margin-bottom: 20px;
    color: var(--themeColourLight);
}

.accountPageRightColumnLayout {
    display: flex;
    flex-direction: column;
}

.supportLayout {
    padding-left: 10px;
}