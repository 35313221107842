.prorationPreviewWrapper {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* text-align: center; */
    flex-direction: column;
}

.prorationPreviewModalWrapper {
    background-color: white;
    margin-left: 100px;
    margin-right: 100px;
    padding: 30px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    border-radius: 5px;
    max-width: 1300px;
    border: var(--themeColourLight) 3px solid;
}

.prorationPreviewModalWrapper h2 {
    margin-top: 0px;
    margin-bottom: 20px;
}

/* .prorationPreviewModalWrapper:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
} */



.confirmSwitchPlanButton {
    width: 330px !important;
}

.selectextLogoSwitchPlan {
    width: 300px;
    margin-bottom: 20px;
}

.upgradingConfirmationMessage {
    width: 100%;
}

.downgradeCancelledWarningMessage {
    color: var(--themeColour);
    margin-top: 10px;
    font-weight: bold;
}