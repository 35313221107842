.topNavBarOption {
    all: unset;
    font-weight: bold;
    display: inline-block;
    margin-left: 20px;
    margin-right: 20px;
    
}

.topNavBarOption:hover {
    color: var(--themeColourLight);
    transition: 0.2s;
}
