.videoScreenshotDemoSlide {
  width: 100%;
  position: absolute; 
  top: 0;
  bottom: 0;
  left: 0;
  height: 100%;
}


.imgLoadingPlaceholder {
  position: relative !important;
  display: inline-block !important;
  width: 100%;
}

