.portalContentWrapper {
    background-color: #fafafa;
    flex: 1;
}

.portalWrapper {
    display: flex;
    height: 100%;
}

.portalTutorialPageWrapper {
    height: 100%;
    overflow-y: auto;
}

.portalTutorialPageWrapper .logoHeaderSmall, .portalTutorialPageWrapper .youWillBePromptedText, .portalTutorialPageWrapper .tryitnowButton  {
    display: none;
}

.portalTutorialPageWrapper .tutmainheader {
    font-size: 40px;
}

.portalTutorialPageWrapper .logoHeaderSmall {
    display: none !important;
}

.portalTutorialPageWrapper {
    padding-top: 40px !important;
}



@media only screen and (max-width: 1000px) {

    .upgradeOptionCostHeader {
        font-size: 35px !important;
    }

    .upgradeCurrencyText {
        font-size: 18px !important;
    }

    .perMonthText {
        font-size: 14px !important;
    }

    .upgradeOptionSmallHeader {
        font-size: 13px !important;
        top: 5px !important;
    }

    .upgradeOptionWrapper {
        width: calc(330px / 1.5);
        height: calc(440px / 1.5);
    }

    .upgradeOptionHeader {
        font-size: 20px !important;
    }

    .upgradeOptionHeaderBlock {
        height: 50px !important;
        min-height: 50px !important;
    }

    .upgradeOptionBenefitsText, .resetsInText {
        font-size: 12px !important;
    }

    .upgradeOptionContentBlock .upgradeButton {
        font-size: 13px !important;
    }

    .costAreaWrapper {
        margin-top: 0px !important;
    }



    .portalNavbar {
        width: 100px;
        min-width: 100px;
    }

    .portalNavbar .navbarButton {
        font-size: 16px;
        line-height: 40px
    }

    .navLogoutButton {
        font-size: 14px;
    }

    .accountPageWrapper, .plansPageWrapper {
        padding: 20px;
    }

    .plansPageWrapper h1 {
        font-size: 25px;
    }

    .accountPageLayout h1 {
        font-size: 22px;
    }


   
    .upgradePageSmallHeader, .asterixText {
        font-size: 14px !important;
    }

    .upgradePageSmallHeader {
        margin-top: 0px !important;
    }

    .availablePlansWrapper {
        padding-top: 20px !important;
        padding-bottom: 20px !important;
    }

    .creditsAmountsWrapper h3 {
        font-size: 30px !important;
    }

    .creditsAmountsWrapper span {
        font-size: 14px !important;
    }

    .yourPlanHeader, .supportHeader {
        margin-top: 10px !important;
        margin-bottom: 10px !important;
    }
}

@media only screen and (max-width: 1200px) and (min-width: 1000px) {
    /* For account page */
    .yourPlansWrapper .upgradeOptionCostHeader {
        font-size: 35px !important;
    }

    .yourPlansWrapper .upgradeCurrencyText {
        font-size: 18px !important;
    }

    .yourPlansWrapper .perMonthText {
        font-size: 14px !important;
    }

    .yourPlansWrapper .upgradeOptionSmallHeader {
        font-size: 13px !important;
        top: 5px !important;
    }

    .yourPlansWrapper .upgradeOptionWrapper {
        width: calc(330px / 1.5);
        height: calc(440px / 1.5);
    }

    .yourPlansWrapper .upgradeOptionHeader {
        font-size: 20px !important;
    }

    .yourPlansWrapper .upgradeOptionHeaderBlock {
        height: 50px !important;
        min-height: 50px !important;
    }

    .yourPlansWrapper .upgradeOptionBenefitsText, .resetsInText {
        font-size: 12px !important;
    }

    .yourPlansWrapper .upgradeOptionContentBlock .upgradeButton {
        font-size: 13px !important;
    }

    .yourPlansWrapper .costAreaWrapper {
        margin-top: 0px !important;
    }




    .upgradeOptionSmallHeader {
        font-size: 13px !important;
        top: 5px !important;
    }

    .upgradeOptionWrapper {
        width: calc(330px / 1.4);
        height: calc(440px / 1.4);
    }

    .upgradeOptionHeader {
        font-size: 22px !important;
    }

    .upgradeOptionHeaderBlock {
        height: 55px !important;
        min-height: 55px !important;
    }

        .upgradeOptionCostHeader {
            font-size: 40px !important;
        }

        .upgradeCurrencyText {
            font-size: 20px !important;
        }

        .perMonthText {
            font-size: 16px !important;
        }

        .upgradeOptionBenefitsText {
            font-size: 13px !important;
        }

        .resetsInText {
            font-size: 12px !important;
        }

        .upgradeOptionContentBlock .upgradeButton {
            font-size: 14px !important;
        }

        .costAreaWrapper {
            margin-top: 10px !important;
        }

    .portalNavbar {
        width: 150px;
        min-width: 150px;
    }

    .portalNavbar .navbarButton {
        font-size: 20px;
    }

    .accountPageWrapper, .plansPageWrapper {
        padding: 25px;
    }

    .plansPageWrapper h1 {
        font-size: 25px;
    }

    .accountPageLayout h1 {
        font-size: 22px;
    }


    
        .upgradePageSmallHeader, .asterixText {
            font-size: 15px !important;
        }
        .upgradePageSmallHeader {
            margin-top: 0px !important;
        }

        .availablePlansWrapper {
            padding-top: 20px !important;
            padding-bottom: 20px !important;
        }

        .creditsAmountsWrapper h3 {
            font-size: 35px !important;
        }
    
        .creditsAmountsWrapper span {
            font-size: 16px !important;
        }

        .yourPlanHeader, .supportHeader {
            margin-top: 12px !important;
            margin-bottom: 12px !important;
        }
}

@media only screen and (max-width: 1350px) and (min-width: 1200px) {
    /* For your plans */
    .yourPlansWrapper .upgradeOptionSmallHeader {
        font-size: 13px !important;
        top: 5px !important;
    }

    .yourPlansWrapper .upgradeOptionWrapper {
        width: calc(330px / 1.4);
        height: calc(440px / 1.4);
    }

    .yourPlansWrapper .upgradeOptionHeader {
        font-size: 22px !important;
    }

    .yourPlansWrapper .upgradeOptionHeaderBlock {
        height: 55px !important;
        min-height: 55px !important;
    }

    .yourPlansWrapper .upgradeOptionCostHeader {
            font-size: 40px !important;
        }

        .yourPlansWrapper .upgradeCurrencyText {
            font-size: 20px !important;
        }

        .yourPlansWrapper .perMonthText {
            font-size: 16px !important;
        }

        .yourPlansWrapper .upgradeOptionBenefitsText {
            font-size: 13px !important;
        }

        .resetsInText {
            font-size: 12px !important;
        }

        .yourPlansWrapper .upgradeOptionContentBlock .upgradeButton {
            font-size: 14px !important;
        }

        .yourPlansWrapper .costAreaWrapper {
            margin-top: 10px !important;
        }




    .upgradeOptionWrapper {
        width: calc(330px / 1.2);
        height: calc(440px / 1.2);
    }

    .upgradeOptionHeader {
        font-size: 25px !important;
    }

    .upgradeOptionHeaderBlock {
        height: 60px !important;
        min-height: 60px !important;
    }

    .upgradeOptionCostHeader {
        font-size: 45px !important;
    }

    .upgradeCurrencyText {
        font-size: 22px !important;
    }

    .perMonthText {
        font-size: 18px !important;
    }

    .upgradeOptionBenefitsText {
        font-size: 16px !important;
    }

    .resetsInText {
        font-size: 14px !important;
    }

    .upgradeOptionContentBlock .upgradeButton {
        font-size: 16px !important;
    }

    .costAreaWrapper {
        margin-top: 20px !important;
    }

    .upgradeOptionSmallHeader {
        top: 5px !important;
        font-size: 14px !important;
    }

    .portalNavbar {
        width: 200px;
        min-width: 200px;
    }

    .portalNavbar .navbarButton {
        font-size: 23px;
    }

    .accountPageWrapper, .plansPageWrapper {
        padding: 30px;
    }

    .plansPageWrapper h1 {
        font-size: 30px;
    }

    .accountPageLayout h1 {
        font-size: 25px;
    }


    

    .upgradePageSmallHeader, .asterixText {
        font-size: 16px !important;
    }
    .upgradePageSmallHeader {
        margin-top: 0px !important;
    }

    .availablePlansWrapper {
        padding-top: 25px !important;
        padding-bottom: 25px !important;
    }

    .creditsAmountsWrapper h3 {
        font-size: 40px !important;
    }

    .creditsAmountsWrapper span {
        font-size: 18px !important;
    }

    .yourPlanHeader, .supportHeader {
        margin-top: 14px !important;
        margin-bottom: 14px !important;
    }
}

.upgradeOptionWrapper .donateButton {
    margin-top: 0px !important;
}

@media only screen and (min-width: 1350px) and (max-width: 1500px) {
    /* For your plans */

    .yourPlansWrapper .upgradeOptionWrapper {
        width: calc(330px / 1.2);
        height: calc(440px / 1.2);
    }

    .yourPlansWrapper .upgradeOptionHeader {
        font-size: 25px !important;
    }

    .yourPlansWrapper .upgradeOptionHeaderBlock {
        height: 60px !important;
        min-height: 60px !important;
    }

    .yourPlansWrapper .upgradeOptionCostHeader {
        font-size: 45px !important;
    }

    .yourPlansWrapper .upgradeCurrencyText {
        font-size: 22px !important;
    }

    .yourPlansWrapper .perMonthText {
        font-size: 18px !important;
    }

    .yourPlansWrapper .upgradeOptionBenefitsText {
        font-size: 14px !important;
    }

    .resetsInText {
        font-size: 14px !important;
    }

    .yourPlansWrapper .upgradeOptionContentBlock .upgradeButton {
        font-size: 16px !important;
    }

    .yourPlansWrapper .costAreaWrapper {
        margin-top: 20px !important;
    }

    .yourPlansWrapper .upgradeOptionSmallHeader {
        top: 5px !important;
        font-size: 14px !important;
    }

    .upgradeOptionWrapper {
        width: 330px;
        height: 440px;
    }

    .portalNavbar {
        width: 250px;
        min-width: 250px;
    }

    .accountPageWrapper, .plansPageWrapper {
        padding: 40px;
    }

    .plansPageWrapper h1 {
        font-size: 30px;
    }

    .accountPageLayout h1 {
        font-size: 25px;
    }


    .upgradePageSmallHeader, .asterixText {
        font-size: 17px !important;
    }

    .yourPlanHeader, .supportHeader {
        margin-top: 16px !important;
        margin-bottom: 16px !important;
    }

    .crementButton {
        width: 40px !important;
        height: 40px !important;
    }

    .incrementalCounter {
        font-size: 25px !important;
        width: 120px !important;
    }
}

@media only screen and (min-width: 1500px) {
    .yourPlansWrapper .upgradeOptionWrapper {
        width: calc(330px / 1.1);
        height: calc(440px / 1.1);
    }

    .yourPlansWrapper .upgradeOptionBenefitsText {
        font-size: 15px !important;
    }

    .resetsInText {
        font-size: 15px !important;
    }

    .crementButton {
        width: 40px !important;
        height: 40px !important;
    }

    .incrementalCounter {
        font-size: 25px !important;
        width: 120px !important;
    }

    .upgradeOptionWrapper {
        width: 330px;
        height: 440px;
    }
    .portalNavbar {
        width: 300px;
        min-width: 300px;
    }

    .accountPageWrapper, .plansPageWrapper {
        padding: 50px;
    }

    .plansPageWrapper h1 {
        font-size: 35px;
    }

    .accountPageLayout h1 {
        font-size: 30px;
    }

    .upgradeOptionHeaderBlock {
        height: 90px;
    }

}


.upgradePageSmallHeader, .asterixText {
    font-size: 20px;
}

.yourPlanHeader, .supportHeader {
    margin-top: 18px;
    margin-bottom: 18px;
}