:root {
  --themeColour: rgb(0, 185, 251);
  --themeColourOpaque: #fafafa;
  --themeColourLight: rgb(0, 185, 251);
  --themeColourLighter: rgba(0, 185, 251, 0.1);
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans'), url(./fonts/open_sans/open-sans-v18-latin-regular.woff) format('woff');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  src: local('Open Sans'), url(./fonts/open_sans/open-sans-v18-latin-800.woff) format('woff');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Open Sans'), url(./fonts/open_sans/OpenSans-Bold.woff) format('woff');
}


.app {
  background-color: var(--themeColourOpaque);
  width: 100%;
  height: 100%;
}

#root {
  width: 100%;
  height: 100%;
  position: absolute;
}

html {
  width: 100%;
  height: 100%;
}

body {
  min-height: 100%;
}

* {
  font-family: "Open Sans", sans-serif !important;
  box-sizing: border-box;
}

.donateButton {
  margin-top: 20px !important;
  border: 2px solid var(--themeColour);
  background-color: var(--themeColourLight) !important;
  font-size: 20px;
  text-transform: none !important;
  width: 100px !important;
  font-weight: bold !important;
}

p {
  font-size: 20px;
  margin: 0;
}

button * {
  font-weight: 800 !important;
}

h1 {
  font-weight: 700;
}

.fullscreenCenter {    
  height: 100vh;
  position: relative;
}

.loadingWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}