.tutorialPageWrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: var(--themeColourOpaque);
}

.tutmainheader {
    margin-bottom: 5px;
    font-size: 50px;
    margin-top: 10px;
    text-align: center;
    font-weight: 700;
}

.logoHeaderSmall {
    width: max(30%, 300px);
    margin-top: 20px
}

.tutviddemowrapper {
    width: 100%;
    text-align: center;
    margin-top: 0;
}

.tutplayerSize {
    width: 60%;
    display: inline-block;
}

.tryoutheader {
    margin-top: 0px;
    margin-bottom: 5px;
    font-size: 40px;
    text-align: center;
    font-weight: 700;
}

.tryoutstart {
    margin-top: 100px;
}

.tryoutdesc {
    margin-top: 0;
    margin-bottom: 0px;
}

.tutorialPageWrapper .videoDemoWrapper {
    text-align: center;
}

.tuttextarea {
    width: 60%;
    font-size: 20px;
    resize: none;
}

@media only screen and (max-width: 850px) {
    .tuttextarea, .byebyewrapper, .followStepsBelow {
        width: 100% !important;
    }
    .tryoutheader, .tutmainheader {
        font-size: 28px !important;
    }

    .tutorialPageWrapper {
        padding-left: 20px;
        padding-right: 20px;
    }
}

@media only screen and (max-width: 1300px) and (min-width: 850px) {
    .tuttextarea, .byebyewrapper, .followStepsBelow  {
        width: 70% !important;
    }
    .tryoutheader, .tutmainheader {
        font-size: 36px !important;
    }
    .tutorialPageWrapper {
        padding-left: 10px;
        padding-right: 10px;
    }

    .tutheader .youHaveUnlockedVideoScreenshotText {
        font-size: 20px;
    }
}

@media only screen and (max-width: 1500px) and (min-width: 1300px) {
    .tuttextarea, .byebyewrapper, .followStepsBelow  {
        width: 70% !important;
    }
    .tryoutheader, .tutmainheader {
        font-size: 44px !important;
    }
    .tutorialPageWrapper {
        padding-left: 10px;
        padding-right: 10px;
    }

    .tutheader .youHaveUnlockedVideoScreenshotText {
        font-size: 22px;
    }
}

@media only screen and (min-width: 1500px) {
    .tutheader .youHaveUnlockedVideoScreenshotText {
        font-size: 24px;
    } 

    .tuttextarea, .byebyewrapper, .followStepsBelow  {
        width: 60% !important;
    }
}


.byebyetext {
    margin-top: 100px;
    font-weight: 700;
}

.byebyesmalltext {
    font-size: 20px;
}

.byebyewrapper {
    text-align: center;
    width: 60%;
    margin-bottom: 100px;
}

.tryitnowButton {
    margin-top: 20px !important;
    border: 2px solid var(--themeColour);
    background-color: var(--themeColourLight) !important;
    font-size: 20px;
    text-transform: none !important;
    width: 150px !important;
    font-weight: bold !important;
    margin-bottom: 10px !important;
}

.tryitnowButton:hover {
    background-color: var(--themeColour) !important;
    cursor: pointer;
}

.followStepsBelow {
    width: 60%;
    text-align: center;
}