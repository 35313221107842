.upgradeOptionWrapper {
    background-color: white;
    display: inline-flex;
    /* width: 300px;
    height: 400px; */
    border: 2px black solid;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    margin-right: 10px;
}


.upgradeOptionWrapper:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;}

.upgradeOptionHeaderBlock {
    width: 100%;
    height: 90px;
    min-height: 90px;
    border-bottom: 2px var(--themeColourLight) solid;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    position: relative;
    box-sizing: border-box;
}

.upgradeOptionHeader {
    font-size: 40px;
    position: absolute;
    margin: 0;
    bottom: 0px;
    font-weight: 800;
}

.upgradeOptionSmallHeader {
    font-size: 15px;
    position: absolute;
    margin: 0;
    top: 10px;
}

.upgradeOptionContentBlock {
    width: 100%;
    padding: 10px;
    height: 100%;
    position: relative;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.upgradeOptionCostHeader {
    font-size: 50px;
    display: inline-flex;
    margin: 0px;
    line-height: 50px;
}

.upgradeButton {
    width: 100% !important;
    min-width: 100% !important;
    /* position: absolute !important; */
    /* bottom: 10px; */
    align-self: flex-end;
    margin-bottom: 10px;
    font-size: 20px !important;
}

.upgradeButtonLink {
    width: 100% !important;
}

.contactButton {
    width: 100% !important;
    font-size: 20px !important;
    margin: 0 !important;
}

.perMonthText {
    font-weight: bold;
    margin: 0;
}

.upgradeOptionBenefitsText {
    text-align: center;
    margin: 0;
    font-weight: 700;
}



.upgradeButton:disabled {
    background-color: #EDEDED !important;
}

.costAreaWrapper {
    margin-top: 30px;
    
}

.costAreaWrapper * {
    font-weight: 800 !important;
}

.upgradeOptionBenefitsWrapper {
    /* height: 124px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
}

.upgradeCurrencyText {
    font-size: 25px;
    line-height: 25px;
    margin-top: 22px;
    margin-left: 3px;
}

.upgradeButtonLink {
    all: unset;
    align-self: baseline;
}

.flipCardWrapper {
    width: calc(100% - 20px) !important;
    position: absolute !important;
    bottom: 10px;
}

.emailContactWrapper {
    height: 47px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.copyEmailButton {
    margin: 0 !important;
    display: inline-block;
    width: 20px !important;
    align-self: center;
    height: 30px;
}

.contactEmailAddress {
    display: inline-block;
    align-self: center;
}

.incrementControlsWrapper {
    display: flex;
    margin-left: 5px;
}

.incrementalCounter {
    margin-left: 5px;
    margin-right: 5px;
    line-height: 30px;
    text-align: center;
    width: 100px;
    background-color: var(--themeColourLighter);
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
}

.crementButton {
    all: unset;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: var(--themeColourLight);
    cursor: pointer;
    position: relative;
}

.crementButton:hover {
    background-color: var(--themeColour);
}

.crementButton:disabled {
    background-color: #EDEDED;
    cursor: unset;
}

.crementText {
    /* font-weight: bold; */
    /* font-size: 30px; */
    /* line-height: 30px; */
    margin: 0;
    padding: 0;
    /* position: absolute; */
    left: 0;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.resetsInText {
    color: black;
    text-align: center;
}


.unlimVidScreenshotWrapper {
    position: relative;
}


.inlineControlWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.newSymbol {
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 20px;
    background-color: white;
    border: 1px solid black;
}

.unlimVideoScreenshotButton {
    all: unset !important;
    font-weight: 700 !important ;
}

.unlimVideoScreenshotButton:hover {
    color: var(--themeColour) !important;
    cursor: pointer !important;
}

.chromeIconUpgrade {
    width: 15px;
    margin-left: 3px;
    margin-bottom: 5px;
}