.tutsmalltext {
    font-size: 16px;
    margin-bottom: 20px;
}

.tutheader {
    margin-bottom: 0;
}

.tutorialGif, .screenshotUnlockedWrapper {
    width: 60%;
}


@media only screen and (max-width: 850px) {
    .tutorialGif, .screenshotUnlockedWrapper {
        width: 100%;
    }
}

@media only screen and (max-width: 1300px) and (min-width: 850px) {
    .tutorialGif, .screenshotUnlockedWrapper {
        width: 60%;
    }
}

@media only screen and (max-width: 1500px) and (min-width: 1300px) {
    .tutorialGif, .screenshotUnlockedWrapper {
        width: 60%;
    }
}

@media only screen and (min-width: 1500px) {
    .tutorialGif, .screenshotUnlockedWrapper {
        width: 50%;
    }
}
