.upgradeFailureWrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
    justify-content: center;
    align-items: center;
}

.upgradeFailureErrorMessage {
    color: red;
    margin-bottom: 10px;
}

.upgradeFailureWrapper .donateButton {
    margin-top: 0px !important;
}

.upgradeFailureWrapper .plainLink {
    margin-top: 20px;
}