.sendEmailLinkButton {
    width: 260px !important;
    margin-left: 20px !important;

}

.emailLinkInputWrappers {
    display: flex;
    align-items: center;
    justify-content: center;
}

.initiateEmailLinkSignInWrapper {
    display: flex;
    flex-direction: column;
    height: 100vh;
    align-items: center;
    justify-content: center;
}


.youWillBeSentText {
    margin-bottom: 10px;
}

.MuiRadio-root {
    color: var(--themeColour) !important;
}

.sendEmailLinkButton:hover {
    background-color: var(--themeColour) !important;
    cursor: pointer;
}

.emailLinkInputWrappers .MuiFormLabel-root.Mui-focused {
    color: var(--themeColour);
}
.emailLinkInputWrappers .MuiInput-underline:after {
    border-color: var(--themeColour) !important;
}
.emailLinkInputWrappers .MuiFormControl-root {
    width: 100%;
}
.emailLinkInputWrappers .MuiFormGroup-root {
    align-items: center;
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-30px);
    }
    60% {
        transform: translateY(-15px);
    }
}

.whichever {
    font-size: 14px;
}