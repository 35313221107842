.loginSuccessPageWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100vh;
  overflow: auto;
}

.loginSuccessLogo {
  width: 350px;
}

.loginSuccessPageWrapper h3 {
  margin: 0;
}

