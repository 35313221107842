.exampleSS {
  width: 500px;
}

/* .newIconSS {
  position: absolute;
  top: 0;
  left: 0;
} */

.rowWrapperSS {
  display: flex;
  justify-content: space-evenly;
}

.newIconSS {
  width: 198px;
  height: 214px;
}

.bottomSSRow {
  margin-top: 20px;
}