.topnavbarwrapper {
    display: flex;
    width: 100%;
    height: 50px;
    background-color: white;
    /* border-bottom: 2px solid var(--themeColourLight) */
    padding: 5px;
    position: relative;
    justify-content: center;
    border-bottom: 1px solid #ededed;
}

.smallIcon {
    width: 40px;
    height: 40px;
    position: absolute;
    left: 5px;
    top: 5px;
}

.topnavbarwrapper .logInButton {
    position: absolute !important;
    right: 5px !important;
    top: 10px !important;
    margin: 0 !important;
    height: 30px !important;
    width: 110px !important;
}

.topNavBarOptionsWrapper {
    justify-content: left;
    display: flex;
    width: 60%;
    line-height: 40px;
    cursor: pointer;
}


.topNavBarOptionsWrapper * {
    font-weight: 800 !important;
}
 

@media only screen and (max-width: 720px){
    .gotoportalbutton {
        display: none;
    }
}