.loader {
    border: 16px solid #faeded; /* Light grey */
    border-top: 16px solid var(--themeColour);
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 1.5s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }