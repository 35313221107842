.subscriptionSuccessPageWrapper {
    width: 100%;
    background-color: #fafafa;
    height: 100%;
    overflow-y: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding-left: 50px;
    padding-right: 50px;
}

.subscriptionSuccessPageWrapper .donateButton {
    margin-top: 0px !important;
}

.subscriptionSuccessPageWrapper .plainLink {
    margin-top: 20px;
}

.returnToPortalButton {
    width: 200px !important;
    margin-top: 20px !important;
}

.plainLink {
    all: unset;
}

.thanksForSubscribing {
    margin-bottom: 0px;
    margin-top: 20px;
}

.subscriptionSuccessPageWrapper .tutorialGif {
    margin-top: auto;
    margin-bottom: auto;
    padding-top: 20px;
    padding-bottom: 20px;
}


.subscriptionSuccessPageWrapper .tutheader {
    margin-top: 0px;
}



@media only screen and (max-width: 850px) {
    .thanksForSubscribing {
        font-size: 24px;
    }
}

@media only screen and (max-width: 1300px) and (min-width: 850px) {
    .thanksForSubscribing {
        font-size: 28px;
    }
}

@media only screen and (max-width: 1500px) and (min-width: 1300px) {
    .thanksForSubscribing {
        font-size: 32px;
    }
}

@media only screen and (min-width: 1500px) {
    .thanksForSubscribing {
        font-size: 40px;
    }
}

.stepperSuccessWrapper {
    width: 100%;
}

.youHaveUnlockedVideoScreenshotText {
    margin-top: 20px;
    margin-bottom: 20px;
}