.signInPageWrapper {
  height: 100vh;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.signInContentWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  border: 2px solid black;
}

.selectextLogoSignin {
  width: 280px;
  margin-bottom: 50px;
}

.signinMethodsWrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.signInToSelectextText {
  font-size: 25px;
  margin-top: 20px;
  margin-bottom: 50px;
}