.lrPad {
    width: 100%;
    box-sizing: border-box;
    padding-left: 5%;
    padding-right: 5%;
    /* background-color: #fafafa; */
    background-color: var(--themeColourLight);
}

.homePageWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    background-color: #fafafa;
}