.plansPageWrapper {
    display: flex;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    flex-direction: column;
    min-width: 500px;
    /* justify-content: space-evenly; */
}

.yourPlansWrapper {
    /* padding-top:30px; */
    width: 100%;
}

.availablePlansWrapper {
    width: 100%;
    padding-top:50px;
    padding-bottom: 50px;
    /* overflow-x: auto; */
    /* overflow-y: visible; */
    /* min-height: 500px; */
}

.planOptionsWrapper {
    flex-direction: row;
    display: flex;
    justify-content: space-evenly;
}

.yourPlansCardsrapper {
    display: flex;
    flex-direction: column;
}

.yourPlansWrapper {
    position: relative;
}

.bottomWrapper {
    margin-top: auto;
    margin-bottom: auto;
}

.upgradePageHeader {
    margin-top: 0;
    font-size: 30px;
    margin-bottom: 0px;
}

.upgradePageHeaderWrapper {
    margin-bottom: 30px;
    align-self: flex-start;
    margin-bottom: auto;
    margin-top: auto;
}

.creditsAvailable, .creditsUsed {
    font-size: 50px;
    color: var(--themeColourLight);
    margin: 0;
    display: inline;
}

.creditsUsedSmallText {
    color: black;
    font-size: 20px;
    font-weight: 700 !important;
}

.bigSlash {
    font-size: 100px;
    display: inline;
}

.creditsAmountsWrapper {
    display: flex;
    flex-direction: column;
}

.creditsAmountsWrapper * {
    font-weight: 800;
}

.horizontalLine {
    width: 220px;
    height: 2px;
    background-color: black;
}

.upgradePageSmallHeader {
    font-weight: normal !important;
    margin: 0;
    margin-top: 10px;
}

.videoScreenshotLink {
    background-color: white;
    text-decoration: none;
    color: black;
    /* border: 2px solid var(--themeColourLight); */
    /* padding: 0px 5px; */
    /* border-radius: 5px; */
}

.videoScreenshotLink:hover {
    color: var(--themeColourLight);
}

.bottomChromeIcon {
    margin: 0 !important;
    margin-right: 5px !important;
}